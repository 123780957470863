<style>
.settings-collapse {
    margin-bottom: 1rem;
}

.hard-border {
    border-radius: 0 !important;
}
</style>
<script>
import { VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import { get_acsrf_token } from "@/methods";
import { mapGetters } from "vuex";

export default {
    props: ['team_id', 'options'],
    components: {
        VclCode,
        //HalfCircleSpinner,
        //Multiselect
    },
    computed: {
        ...mapGetters([
            'getTeams'
        ]),
    },
    methods: {
        handleError: function (error) {
            console.log(`[ERROR] ${error}`);
            this.error = true;
        },
        async deleteTeam() {
            let result = await this.$swal({
                icon: 'warning',
                text: this.$t('teams.view.settings.dangerzone.delete.confirm.text'),
                title: this.$t('teams.view.settings.dangerzone.delete.confirm.title'),
                showCancelButton: true,
                confirmButtonText: this.$t('teams.view.settings.dangerzone.delete.button'),
                confirmButtonColor: '#f46a6a',
                cancelButtonColor: '#c3cbe4',
            });
            if (!result.isConfirmed) {
                return;
            }
            this.$emit('actionEnableFullpage', this.$t('teams.view.settings.dangerzone.delete.in_progress'), 'danger', true);
            this.$emit('actionToggleFullpage');

            await new Promise(resolve => setTimeout(resolve, 1000));

            try {
                let acsrf_token = await get_acsrf_token();
                let payload = {
                    acsrf_token: acsrf_token
                };
                let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/delete`, {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    credentials: 'include'
                });
                if (response.ok) {
                    this.$store.commit('removeTeam', { id: this.team_id })
                    if (this.options.organization_id) {
                        await this.$router.push({ name: 'organization', params: { organization_id: this.options.organization_id } });
                    } else {
                        await this.$router.push({ name: 'teams' });
                    }
                } else {
                    if (response.status === 403) {
                        this.$swal({
                            icon: 'error',
                            text: this.$t('error.permissions.message'),
                            title: this.$t('error.permissions.title')
                        });
                    } else if (response.status === 429) {
                        this.$swal({
                            icon: 'warning',
                            text: this.$t('error.server.ratelimit.action'),
                            title: this.$t('error.server.ratelimit.title')
                        });
                    } else {
                        throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                    }
                }
            } catch (error) {
                console.log(`[ERROR] ${error}`);
                this.$swal({
                    icon: 'error',
                    text: this.$t('error.server.generic.message')
                });
            }
            this.$emit('actionToggleFullpage');
        },
        async fetchOrganizations() {
            const url = new URL(process.env.VUE_APP_ROOT_API + 'v1/organizations');
            url.searchParams.append('permissions', 'organization.teams.add');
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) {
                this.error = true;
                if (response.status === 429) {
                    this.$swal({
                        icon: 'warning',
                        text: this.$t('error.server.ratelimit.message'),
                        title: this.$t('error.server.ratelimit.title')
                    });
                }
                return;
            }
            const data = await response.json();
            this.organizations = data.organizations;
            if (!await this.fetchOrganization()) {
                return;
            }
            this.ready = true;
        },
        async fetchOrganization() {
            if (this.options.organization) {
                this.organization = this.$store.getters.getOrganization(this.options.organization);
                if (!this.organization.permissions) {
                    const response = await fetch(process.env.VUE_APP_ROOT_API + `v1/organizations/${this.organization.id}`, {
                        method: 'GET',
                        credentials: 'include'
                    });
                    if (!response.ok) {
                        this.error = true
                        if (response.status === 429) {
                            this.$swal({
                                icon: 'warning',
                                text: this.$t('error.server.ratelimit.message'),
                                title: this.$t('error.server.ratelimit.title')
                            });
                        } else if (response.status === 403) {
                            this.unauthorized = true;
                        }
                        return false;
                    }
                    const data = await response.json();
                    this.$store.commit('update', () => this.organization = Object.assign(this.organization, data.organization, { permissions: data.permissions, role: data.role }));
                    this.$store.commit('updateOrganization', this.organization);
                }
            }
            return true
        },
        async assignToOrganization() {
            if (!this.selectedOrganization) {
                return;
            }
            this.submitted = true;
            const acsrf_token = await get_acsrf_token();
            const payload = {
                acsrf_token,
                organization_id: this.selectedOrganization.id
            };
            const response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/organization`, {
                method: 'POST',
                body: JSON.stringify(payload),
                credentials: 'include'
            })
            if (response.ok) {
                this.organization = this.selectedOrganization;
                this.selectedOrganization = null;
                if (await this.fetchOrganization()) {
                    this.$swal({
                        icon: 'success',
                        title: this.$t('teams.view.settings.organization.add.success')
                    });
                }
            } else {
                if (response.status === 429) {
                    this.$swal({
                        icon: 'warning',
                        text: this.$t('error.server.ratelimit.message'),
                        title: this.$t('error.server.ratelimit.title')
                    });
                } else {
                    this.$swal({
                        icon: 'error',
                        title: this.$t('error.server.generic.message')
                    });
                }
            }
            this.submitted = false;
        },
        async removeFromOrganization() {
            this.submitted = true;
            const acsrf_token = await get_acsrf_token();
            const payload = {
                acsrf_token
            };
            const response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/organization`, {
                method: 'DELETE',
                body: JSON.stringify(payload),
                credentials: 'include'
            })
            if (response.ok) {
                this.organization = null;
                this.$swal({
                    icon: 'success',
                    title: this.$t('teams.view.settings.organization.remove.success')
                });
            } else {
                if (response.status === 429) {
                    this.$swal({
                        icon: 'warning',
                        text: this.$t('error.server.ratelimit.message'),
                        title: this.$t('error.server.ratelimit.title')
                    });
                } else {
                    this.$swal({
                        icon: 'error',
                        title: this.$t('error.server.generic.message')
                    });
                }
            }
            this.submitted = false;
        }
    },
    mounted() {
        this.fetchOrganizations();
    },
    data() {
        return {
            ready: false,
            error: false,
            inProgress: false,
            settings: {},
            transmission: {
                public: null,
                evidence_public: null,
                allow_followers: null,
                keep_inactive: null
            },
            organization: null,
            organizations: null,
            selectedOrganization: null,
            submitted: false
        }
    }
};
</script>

<template>
    <div>
        <template v-if="ready">
            <div class="row">
                <div class="col-lg-9">
                    <div>
                        <div role="tablist">
                            <!-- Organization -->
	                          <!--
                            <b-card no-body class="settings-collapse hard-border">
                                <a v-b-toggle.organization class="text-dark" href="javascript: void(0);">
                                    <b-card-header header-tag="header" role="tab">
                                        <h3 class="m-0 d-inline-flex">
                                            {{ $t('teams.view.settings.organization.title') }}
                                        </h3>
                                        <div class="d-inline-flex float-right">
                                            <div class="btn btn-sm btn-dark">
                                                {{ $t('teams.view.settings.expand') }}
                                            </div>
                                        </div>
                                    </b-card-header>
                                </a>
                                <b-collapse id="organization" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <div class="row">
                                                <div class="col justify-content-center">
                                                    <h5 class="text-center text-muted">
                                                        {{ $t('teams.view.settings.organization.description') }}
                                                    </h5>
                                                </div>
                                            </div>
                                            <template v-if="!organization">
                                                <div class="row justify-content-center">
                                                    <div class="col-lg-6 col-sm-12">
                                                        <multiselect v-model="selectedOrganization" label="id" track-by="id" :options="organizations" :show-labels="false"
                                                                     :allow-empty="false">
                                                            <template slot="singleLabel" slot-scope="props">
                                                                <div>
                                                                    <span class="option__title align-middle h4">
                                                                      {{ props.option.identifier }}
                                                                    </span>
                                                                </div>
                                                            </template>
                                                            <template slot="option" slot-scope="props">
                                                                <div>
                                                                    <div class="option__desc d-inline ml-2">
                                                                        <span class="option__title align-middle h4">
                                                                          {{ props.option.identifier }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                                <div v-if="options.permissions.organization && options.permissions.organization.assign" class="row mt-2 justify-content-center">
                                                    <div class="col-lg-3 col-sm-12">
                                                        <button class="btn btn-primary btn-block" v-on:click="assignToOrganization()" :class="{'disabled': submitted}">
                                                            <half-circle-spinner
                                                                    v-if="submitted"
                                                                    :animation-duration="1200"
                                                                    :size="16"
                                                                    class="align-middle d-inline-block"
                                                            />
                                                            {{ $t('teams.view.settings.organization.add.button') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="row justify-content-center">
                                                    <div class="col-lg-6 col-sm-12 text-center">
                                                        <span class="option__title align-middle h4">
                                                          {{ organization.identifier }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-if="options.permissions.organization && options.permissions.organization.remove" class="row mt-2 justify-content-center">
                                                    <div class="col-lg-3 col-sm-12">
                                                        <button class="btn btn-warning text-black btn-block" v-on:click="removeFromOrganization()"
                                                                :class="{'disabled': submitted}">
                                                            <half-circle-spinner
                                                                    v-if="submitted"
                                                                    :animation-duration="1200"
                                                                    :size="16"
                                                                    class="align-middle d-inline-block"
                                                            />
                                                            {{ $t('teams.view.settings.organization.remove.button') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            -->

                            <!-- Dangerzone -->
                            <b-card no-body class="settings-collapse border border-danger hard-border">
                                <a v-b-toggle.accordion-3 class="text-dark" href="javascript: void(0);">
                                    <b-card-header header-tag="header" role="tab">
                                        <h3 class="m-0 d-inline-flex text-danger">
                                            {{ $t('teams.view.settings.dangerzone.title') }}
                                        </h3>
                                        <div class="d-inline-flex float-right">
                                            <div class="btn btn-sm btn-dark">
                                                {{ $t('teams.view.settings.expand') }}
                                            </div>
                                        </div>
                                    </b-card-header>
                                </a>
                                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <div class="row" v-if="options.permissions.delete">
                                                <div class="w-75">
                                                    <div class="ml-4 mr-4">
                                                        <h4 class="text-danger">
                                                            {{ $t('teams.view.settings.dangerzone.delete.title') }}
                                                        </h4>
                                                        <span>
                                                            {{ $t('teams.view.settings.dangerzone.delete.description') }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="w-25">
                                                    <div style="text-align: center;">
                                                        <button class="btn btn-block btn-danger" v-on:click="deleteTeam()">
                                                            {{ $t('teams.view.settings.dangerzone.delete.button') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="error">
            <div class="row">
                <div class="col-lg-12 col-sm-12">
                    <div class="card border border-danger">
                        <div class="card-header bg-transparent border-danger">
                            <h5 class="my-0 text-danger">
                                <i class="far fa-exclamation-circle mr-3"></i>
                                {{ $t('error.server.generic.title') }}
                            </h5>
                        </div>
                        <div class="card-body pt-0">
                            <h5 class="card-title mt-0"> {{ $t('error.server.generic.component') }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-lg-11">
                    <!-- Content row one -->
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                        <div class="col-lg-4">
                                            <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
